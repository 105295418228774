<template>
  <vuestic-widget :loading="loading" class="automation-create-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">New Template</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="userCreateForm">
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="templateCreateForm">
        <div class="row">
          <div class="col-sm-12">
            <text-input name="Name" v-model="formData.name" label="Name" validate="required" />
          </div>
          <div class="col-sm-12">
            <text-input name="Order" v-model="formData.order" label="Order" validate="required" />
          </div>
          <div v-if="provider === 'sms'" class="col-md-12 mt-2">
            <validation-provider rules="required" v-slot="{}" name="Message">
              <TextareaEmojiPicker v-model="formData.message" :loading="loading" :focused=true :rows="7"
                :enabledMms="true" ref="textareaEmoji" :maxLength="maxLength" :showSendButton="false"
                :hasHoverEffect="false" :optText="optText" @done="onSelectImg" />
            </validation-provider>
          </div>
          <div v-else-if="provider === 'email'" class="col-md-12 mt-2">
            <validation-provider rules="required" v-slot="{}" name="Message">
              <TextEditorPicker v-model="formData.message" :loading="loading" :enabledEmoji="true"
                :height="280" :showSendButton="false" ref="texteditor" @done="onSelectImg" />
            </validation-provider>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary btn-danger mr-2" @click="onBack">
              <span>Back <i class="fa fa-arrow-left"></i></span>
            </button>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto;" />
              <span v-else>Create</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
import { mapState } from 'vuex';
import TextareaEmojiPicker from '@/components/common/TextareaEmojiPicker'
import TextEditorPicker from '@/components/common/TextEditorPicker'

export default {
  components: {
    TextareaEmojiPicker, TextEditorPicker,
  },
  data() {
    return {
      loading: false,
      users: [],
      provider: 'sms',
      formData: {
        name: '',
        message: '',
        medias: [],
        order: 1,
      },
    };
  },

  computed: {
    ...mapState('template', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
    }),

    optText() {
      return this.$store.getters['auth/optText']
    },

    maxLength() {
      return 1600 - this.optText.length
    },

    business() {
      return this.$store.state.auth.user.business
    }
  },
  mounted() {
    this.provider = this.$route.query.type ? this.$route.query.type : 'sms'
  },
  methods: {
    onBack() {
      this.$router.push({ name: 'business.templates.index', query: { type: this.provider } })
    },

    onSelectImg(imgs) {
      this.formData.medias = imgs
    },

    onFormSubmit() {
      const param = {
        ...this.formData,
        provider: this.provider,
      }
      this.$store
        .dispatch('template/create', param)
        .then(() => {
          this.$router.push({ name: 'business.templates.index', query: { type: this.provider } });
        })
        .catch(() => {
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-dropzone {
  border: 2px dashed #e5e5e5;
}

.templateCreateForm {
  max-width: 768px;
}
</style>